import {importProvidersFrom} from '@angular/core';
import {Routes} from '@angular/router';
import {AuthCallbackComponent} from '@auth/feature/auth-callback-view';
import {ReadOnlyPageComponent} from '@common/components/read-only-page/read-only-page.component';
import {roleGuard} from '@common/guards/role.guard';
import {CompareProjectViewState} from '@compare/data-access/project-view';
import {OrganizationsCardState} from '@layout/data-access/organizations-card';
import {LayoutDefaultComponent} from '@layout/feature/default';
import {NgxsModule} from '@ngxs/store';
import {ProjectBreadcrumbsState} from '@project/data-access/breadcrumbs';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';

import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    providers: [
      importProvidersFrom(NgxsModule.forFeature([ProjectBreadcrumbsState])),
      importProvidersFrom(NgxsModule.forFeature([CompareProjectViewState])),
      importProvidersFrom(NgxsModule.forFeature([OrganizationsCardState])),
    ],
    children: [
      {
        path: '',
        redirectTo: 'portfolio',
        pathMatch: 'full',
      },
      {
        path: 'portfolio',
        data: {breadcrumb: 'Портфели проектов'},
        loadChildren: () => import('@portfolio/feature/shell').then(c => c.routes),
      },
      {
        path: 'references',
        data: {breadcrumb: 'Группы справочников'},
        loadChildren: () => import('@catalog-group/feature/shell').then(c => c.routes),
      },
      {
        path: 'references-list',
        data: {breadcrumb: 'Список справочников'},
        loadChildren: () => import('@catalog-list/feature/shell').then(c => c.routes),
      },
      {
        path: 'organizations',
        data: {breadcrumb: 'Организации'},
        loadChildren: () => import('@organizations/feature/shell').then(c => c.routes),
      },
      {
        path: 'users',
        data: {breadcrumb: 'Пользователи'},
        loadChildren: () => import('@users/feature/shell').then(c => c.routes),
      },
      {
        path: 'roles',
        data: {breadcrumb: 'Роли пользователей'},
        loadChildren: () => import('@roles/feature/shell').then(c => c.routes),
      },
      {
        path: 'fc8aab6e-a17e-444f-b681-edb36ed2e272',
        data: {breadcrumb: 'Доступ'},
        loadChildren: () => import('@local-permission/feature/shell').then(c => c.routes),
      },
    ],
  },
  {
    path: 'build-info',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    data: {breadcrumb: 'Build info'},
    loadChildren: () => import('@build-info/feature/shell').then(c => c.routes),
  },
  {
    path: 'page-ifc-viewer',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('./page-ifc-viewer').then(m => m.routes),
  },
  {
    path: 'structure-viewer',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('./structure-viewer').then(m => m.routes),
  },
  {
    path: 'diagnostic-structure-viewer',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('./diagnostic-structure-viewer').then(m => m.routes),
  },
  {
    path: 'slot-constructor',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('@slot-constructor/routes').then(m => m.routes),
  },
  {path: 'read-only', component: ReadOnlyPageComponent},
  {
    path: '**',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    component: PageNotFoundComponent,
  },
];
